import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import Routes from "./routes";
import {
  FORMS_SET_QUERY,
  CITY_QUERY,
  GET_ITEMS_QUERY,
  FINANCE_DEPARTMENTS,
  FINANCE_lOCATIONS,
  FETCH_FINANCE_ACCOUNTS,
  CITY_LIST_CUSTOMER,
} from "./graphql/query";
import FormContextProvider, {
  FormContext,
  FormActions,
  useFormReducer,
} from "./context/FormContext";
import { PERSON_SET_QUERY } from "graphql/person";
import { AuthContext } from "context/AuthContext";
import { useRecoilState, useRecoilValue } from "recoil";
import { KEYS, listProviderAtom } from "store/atoms/city";
// import { authContext } from './context/AuthContext';

function App() {
  const { formState, formDispatch } = useFormReducer();
  const [cityList, setCityList] = useRecoilState(listProviderAtom(KEYS.CITY));
  const [itemList, setItemList] = useRecoilState(listProviderAtom(KEYS.ITEMS));
  const [allCity] = useRecoilState(listProviderAtom(KEYS.CITY));

  const [_, setPrefferedStore] = useRecoilState(
    listProviderAtom(KEYS.PREFERRED_STORE)
  );

  const { formState: FormState } = useContext(FormContext);

  const { currentCity } = FormState;

  const context = AuthContext;
  const userContext = useContext(context);

  const city: any = currentCity;

  useEffect(() => {
    if (city?.id) {
      loadAccounts();
      setCityDropDown();
      getCityStores();
    }
    formDispatch({
      type: FormActions.SET_CURRENT_CITY,
      payload: city,
    });
  }, [city?.id, cityList?.length]);

  const setCityDropDown = () => {
    formDispatch({
      type: FormActions.SET_CURRENT_CITY,
      payload: city,
    });

    formDispatch({
      type: FormActions?.SET_CURRENT_CITY_DROPDOWN_DATA,
      payload: cityList?.filter((each) => each?.id === city?.id),
    });
  };

  const token = localStorage.getItem("t");
  useEffect(() => {
    if (
      localStorage.getItem("t") &&
      localStorage.getItem("t") !== "" &&
      localStorage.getItem("t") !== null &&
      localStorage.getItem("t") !== undefined
    ) {
      const token = localStorage.getItem("t");
      userContext?.setToken(token);
    }
  }, []);

  useQuery(CITY_QUERY, {
    fetchPolicy: "cache-and-network",

    onCompleted(data) {
      setCityList(data?.cities);
      formDispatch({ type: FormActions.SET_ALL_CITIES, payload: data.cities });
      setCityDropDown();

      // setCurrentCity(UserState?.mainCity)
    },
  });

  useQuery(FORMS_SET_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      formDispatch({ type: FormActions?.SET_FORMS, payload: data?.forms });
    },
  });

  // useQuery(GET_ITEMS_QUERY, {
  //   fetchPolicy:"cache-and-network",
  //   onCompleted(data) {

  //     formDispatch({ type: FormActions?.SET_ORDER_ITEMS, payload: data.items })
  //   },
  // })

  const [getCityStores] = useLazyQuery(CITY_LIST_CUSTOMER, {
    variables: { id: Number(city?.id) },
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      const result = data.citystore;
      setPrefferedStore(result);
    },
    onError(error) {
      console.error(error);
    },
  });

  useQuery(FINANCE_DEPARTMENTS, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      formDispatch({
        type: FormActions?.SET_FINANCE_DEPARTMENTS,
        payload: data?.departments,
      });
    },
  });

  useQuery(FINANCE_lOCATIONS, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      formDispatch({
        type: FormActions?.SET_FINANCE_LOCATIONS,
        payload: data?.locations,
      });
    },
  });

  const [loadAccounts] = useLazyQuery(FETCH_FINANCE_ACCOUNTS, {
    fetchPolicy: "cache-and-network",
    variables: { id: Number(city?.id) },

    onCompleted(data) {
      formDispatch({
        type: FormActions?.SET_FINANCE_ACCOUNTS,
        payload: data?.bankAccountCity,
      });
    },
  });

  return (
    <div className="App">
      <React.StrictMode>
        <Router>
          <Routes />
        </Router>
      </React.StrictMode>
    </div>
  );
}

export default App;
