import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import "../common/common.scss";
import InputBox from "components/common/InputBox";
import AutocompleteBox from "components/common/AutocompleteBox";
import DatepickerBox from "components/common/DatepickerBox";
import "../common/common.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AutocompleteBoxCustom from "components/common/AutocompleteBoxCustom";
import { format, isLeapYear } from "date-fns";
import { toast } from "react-toastify";
import { AuthContext } from "context/AuthContext";
import RadioBox from "components/common/RadioBox";
import personState from "store/atoms/person/personAtom";
import { useRecoilState } from "recoil";
import { FormContext } from "context/FormContext";
import { CitiesContext } from "context/CitiesContext";
import AutocompleteBoxArea from "components/common/Autocompleteboxarea";
import customerDataState from "pages/sales/saleAtom";
import moment from "moment";
import CustomLoader from "components/common/CustomLoader";
import { KEYS, listProviderAtom } from "store/atoms/city";
import EmployeeAutoComplete from "components/common/EmployeeAutoComplete";

type SalesFormDialogProps = {
  title?: string;
  open: boolean;
  form?: any;
  handleClose: () => void;
  custData?: any;
  createSaleFun: any;
  formType?: string;
  updateData?: any;
  edit?: any;
  sales?: any;
  submitLoader: boolean;
};

const mapper = {
  LI: AutocompleteBoxCustom,
  ST: InputBox,
  LT: InputBox,
  RA: RadioBox,
  DT: DatepickerBox,
  CU: AutocompleteBoxCustom,
};

interface IFormInputs {
  customersuggestions: any;
  timespentwithcustomer: any;
  shareddauusremarkablestory: any;
  teachcustomersomethingnew: any;
  productplacementonshelves: any;
  howmanymothersyouswitched: any;
  productmothersswitchedto: any;
  customersatisfactionscore: any;
  nameofcompetitorcompany: any;
  typeofnewproduct: any;
  nameofproduct: any;
  priceofproduct: any;
  unitspercarton: any;
  companyname: any;
  productname: any;
  typeofchange: any;
  changescompetitormade: any;
  describechange: any;
  updateData: any;
  edit: any;
  sales: any;
}

const SalesFormDialog: React.FC<SalesFormDialogProps> = ({
  title = "",
  open,
  form,
  handleClose,
  custData,
  createSaleFun,
  formType,
  updateData,
  edit,
  sales,
  submitLoader,
}) => {
  const [person, setPerson] = useRecoilState(personState);
  const { userDetails } = useContext(AuthContext);
  const [customerData, setCustomerData] = useRecoilState(customerDataState);
  const userData = userDetails?.availableCities || [];
  const { formState } = useContext(FormContext);
  const { cities, areas, nhoods } = formState.cityDropdown;
  const [modifiedNhoods, setModifiedNhoods] = useState([]);
  const [preferredStore] = useRecoilState(
    listProviderAtom(KEYS.PREFERRED_STORE)
  );

  let defaltValues = {};
  let newData = {};
  let answers = [];

  let schema;


  const mappDefaultValue = () => {
    let aa = {};
    if (sales) {
      form?.sections?.forEach((parent) => {
        parent?.question?.forEach((child) => {
          modifiedData(child.displayTitle, aa);
        });
      });
    } else {
      form?.sections?.forEach((parent) => {
        parent.question.forEach((child) => {
          modifiedData(child.displayTitle, aa);
        });
      });
    }

    return aa;
  };

  const modifiedData = (addkey, aa) => {
    // let key = addkey.replace(/[^A-Z0-9]+/ig, "").toLowerCase()
    // let newKey = JsonMap[key] || key

    if (sales) {
      updateData &&
        updateData?.salesanswer?.forEach((item) => {
          // Generate the new key from displayTitle
          let newKey2 = item?.question?.id



          // Assigning values to aa with the newKey as the dynamic key
          aa[newKey2] = item.salesanswer;
        });
    } else {
      updateData &&
        updateData?.answer?.forEach((item) => {
          // Generate the new key from displayTitle
          let newKey = item?.question?.id

          aa[newKey] = item?.answer;
        });
    }

    if (!sales) {
      aa["interactionBy"] = updateData?.interactionBy?.id;
      aa["areaInteraction"] = updateData?.area?.id;
      aa["nhoodInteraction"] = updateData?.nhood?.id;
    }


    aa["dateOfInteraction"] =
      updateData?.dateOfVisited || moment(new Date()).format("YYYY-MM-DD");
  };
  const formSchema = ({ form }) => {
    const data = form;
    const validationSchema = {};
    data?.sections.forEach((section) => {
      section?.question.forEach((question) => {
        const modifiedName = question?.id
        validationSchema[modifiedName] = yup
          .string()
          .required("This field is required.")
          .label(question.displayTitle);
      });
    });

    const defaultSchema = {
      dateOfInteraction: yup.string().required(),
      areaInteraction: yup.string().required(),
      nhoodInteraction: yup.string().required(),
      interactionBy: yup.string().required(),
    };

    const salesDefaultSchema = {
      dateOfInteraction: yup.string().required(),
    }

    const finalSchema = { ...(sales ? salesDefaultSchema : defaultSchema), ...validationSchema };

    console.log(finalSchema, "xxx");
    return yup.object().shape(validationSchema);
  };
  schema = formSchema({ form });

  console.log(schema, "xxx");

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: mappDefaultValue(),
    resolver: yupResolver(schema),
  });

  const watchArea = watch("areaInteraction");

  const [value, setValues] = useState(new Date());
  const handleChange = (newValue: any) => {
    setValues(newValue);
  };
  const formatDate = (date) => {
    if (date && date !== undefined) {
      let newDate = new Date(date);
      return format(newDate, "yyyy-MM-dd");
    }
  };

  useEffect(() => {
    const value = getValues("areaInteraction");
    let area = areas?.find((me) => me.id === value)?.value;
    let hoods = nhoods?.filter((me) => me.area === area);
    setModifiedNhoods([...hoods]);
  }, [watch, watchArea]);

  const onResetFields = () => {
    for (let key in schema?.fields) {
      clearErrors(key);
    }
  };

  console.log(form, "sxsx");

  const onSubmit = (data: IFormInputs) => {
    let categoryQuestions = [];
    for (let i = 0; i < form?.sections.length; i++) {
      for (let j = 0; j < form?.sections[i]["question"].length; j++) {
        categoryQuestions.push(form?.sections[i]["question"][j]);
      }
    }





    let questionId = 0;
    let matchFound = false;
    for (let key in data) {
      newData[key] = getValues(key);
      matchFound = false;
      if (categoryQuestions?.some((qt) => qt.id === key)) {
        answers.push({
          question: Number(key),
          answer: getValues(key),
        });
      }

    }


    console.log(answers, "xcxc");


    let getData = { ...newData };

    // Sales Interaction >>>
    if (sales) {
      newData = {
        city: parseInt(formState.currentCity?.id),
        comment: getData?.comments ? getData?.comments : "",
        dateOfInteraction:
          formatDate(getData?.dateOfInteraction) ||
          moment(new Date()).format("YYYY-MM-DD"),
        form: parseInt(form?.id),
        customer: parseInt(customerData?.id),
        questionAnswer: answers,
        isPositive: 1,
      };
    }
    // Crm Interaction >>>
    else {
      newData = {
        city: parseInt(formState.currentCity?.id),
        comment: getData?.comments ? getData?.comments : "",
        dateOfInteraction:
          formatDate(getData?.dateOfInteraction) ||
          moment(new Date()).format("YYYY-MM-DD"),
        form: parseInt(form?.id),
        isPositive: 1,
        person: parseInt(person?.id),
        questionAnswer: answers,
        interactionBy: getData.interactionBy,
        area: parseInt(getData?.areaInteraction),
        nhood: parseInt(getData?.nhoodInteraction),
      };
    }




    // Add Edit id to the object..
    if (edit) {
      newData.id = parseInt(updateData?.id);
    }

    createSaleFun({
      variables: newData,
    });
  };

  console.log({ preferredStore });

  const propsMapper = (row) => {
    const { id, qType } = row;
    let name = row?.id
    let props: any = { key: id };
    switch (qType.toUpperCase()) {
      case "LI": {
        props = {
          ...props,
          title: row.displayTitle,
          data: row.answerOption.map((d) => {
            return { label: d.displayTitle, value: d.value };
          }),
          name: name,
          control: control,
          error: errors[name]?.message,
          required: true,
        };
        break;
      }

      case "CU": {
        props = {
          ...props,
          title: row.displayTitle,
          data: preferredStore.map((d) => {
            return {
              label: `${d.entityId} | ${d.name} | ${d?.rep?.shortName} `,
              value: `${d.entityId} | ${d.name} | ${d?.rep?.shortName} `,
            };
          }),
          name: name,
          control: control,
          error: errors[name]?.message,
          required: true,
        };
        break;
      }

      case "ST": {
        props = {
          ...props,
          title: row.displayTitle,
          name: name,
          control: control,
          error: errors[name]?.message,
          required: true,
          value: "Tests",
        };
        break;
      }
      case "LT": {
        props = {
          ...props,
          title: row.displayTitle,
          name: name,
          control: control,
          error: errors[name]?.message,
          required: true,
        };
        break;
      }

      case "DT": {
        props = {
          ...props,
          title: row.displayTitle,
          name: name,
          control: control,
          error: errors[name]?.message,
          required: true,
        };
        break;
      }

      case "RA": {
        props = {
          ...props,

          title: row.displayTitle,
          name: name,
          control: control,
          error: errors[name]?.message,
          required: true,
          data: row.answerOption.map((d) => {
            return { label: d.displayTitle, value: d.value };
          }),
        };
        break;
      }
      default:
    }

    return props;
  };

  const onError = (errors, e) => console.log("error while submit", errors, e);
  return (
    <Dialog
      open={open}
      onClose={() => {
        onResetFields();
        handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "personBaseDialog",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid className="row">
            <DatepickerBox
              title="Interaction Date"
              value={value}
              onChange={handleChange}
              control={control}
              name="dateOfInteraction"
              required={true}
              error={errors?.dateOfInteraction?.message}
            />

            {!sales && (
              <>
                <EmployeeAutoComplete
                  control={control}
                  name="interactionBy"
                  error={errors?.interactionBy?.message}
                />

                <AutocompleteBoxArea
                  title={"Area"}
                  data={areas}
                  control={control}
                  name="areaInteraction"
                  error={errors?.areaInteraction?.message}
                />

                <AutocompleteBoxArea
                  title={"Neighborhood"}
                  data={modifiedNhoods}
                  control={control}
                  name="nhoodInteraction"
                  error={errors?.nhoodInteraction?.message}
                />
              </>
            )}

            {form?.sections?.map((me, index) => {
              return (
                <>
                  <Box className="formGroup formGroup__title col-12">
                    <Typography variant="h6" className="title">
                      {me.displayTitle}
                    </Typography>
                  </Box>
                  {me?.question?.map((q) => {
                    return (
                      <>
                        {React.createElement(
                          mapper[q.qType.toUpperCase()],
                          propsMapper(q)
                        )}
                      </>
                    );
                  })}
                </>
              );
            })}
          </Grid>
        </DialogContent>
        {submitLoader ? (
          <CustomLoader />
        ) : (
          <DialogActions>
            <Button
              className="btn-text"
              onClick={() => {
                onResetFields();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button className="btn-text" type="submit">
              {edit ? "Update" : "Save"}{" "}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
export default SalesFormDialog;
