import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  Typography,
} from "@mui/material";
import AutocompleteBox from "components/common/AutocompleteBox";
import TabBox from "components/common/TabBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableBox from "components/common/TableBox";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialogBox from "components/common/DeleteDialogBox";
import DateRangeBox from "components/common/DateRangeBox";
import ButtonBox from "components/common/ButtonBox";
import "./finance.scss";
import {
  FETCH_PENDING_FINANCE_TRANSACTION,
  GET_ITEMS_QUERY,
} from "graphql/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import NotFound from "components/common/NotFound";
import {
  DELETE_SALES_ORDER,
  DELETE_SALES_ORDER_PAYMENT,
  GET_MY_SUBMITTED_ORDERS,
} from "graphql/mutation";
import { toast } from "react-toastify";
import CitySearch from "components/common/CitySearch";
import AddSaleDialog from "components/sales/AddSaleDialog";
import { useRecoilState } from "recoil";
import customerDataState from "pages/sales/saleAtom";
import {
  GET_SALES_ORDER_DETAILS,
  GET_SUBMITTED_ORDER_TRANSACTION,
} from "graphql/query/sales";
import MakeSalesPayment from "components/payment/MakeSalesPayment";
import { FormActions, FormContext, useFormReducer } from "context/FormContext";
import { error } from "console";
import { getInvoiceAmount } from "utils/Constant";

let financeQuery = {
  pending: FETCH_PENDING_FINANCE_TRANSACTION,
  history: GET_MY_SUBMITTED_ORDERS,
};

const Finance = (props) => {
  console.log({ props }, "props");
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openSale, setOpenSale] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const [salesTransaction, setSalesTransaction] = useState([]);
  const [filterPayments, setFilterPayments] = useState([]);
  const [filterSales, setFilterSale] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [editable, setEditable] = useState(false);
  const [showPaymentModal, setPaymentModal] = useState(false);
  const [customerData, setCustomerData] = useRecoilState(customerDataState);
  const [salesLoader, setSalesLoader] = useState(true);
  const [paymentsLoader, setPaymentsLoader] = useState(true);
  const { formState, formDispatch } = useFormReducer();

  const FormState = useContext(FormContext);

  const { currentCity } = FormState.formState;

  const params = useParams();
  const location = useLocation();
  console.log({ params, location });

  const cityId = Number(currentCity.id);

  console.log("customerData in fianace", customerData);
  console.log("FormState", FormState);

  const onEyeClick = (row) => {
    console.log({ row });
    let type = row.type;
    console.log("called...", type);

    if (type === "transaction") {
      setPaymentModal(true);
      setCurrentRow(row);
    } else {
      // getSalesOrderDetails({ variables: { id: Number(row?.id) } })
      setCurrentRow(row);
      setOpenSale(true);
      setEditable(true);
    }
  };

  const headers = [
    {
      key: "date",
      label: "Date",
    },
    {
      key: "salescustomer",
      label: "Customer",
    },
    {
      key: "salesUser",
      label: "Sales User",
    },
    {
      key: "invoiceAmount",
      label: "Invoice Amount",
    },
    {
      key: "source",
      label: "Source",
    },
    {
      key: "status",
      label: "Status",
    },

    {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element: ({ row }) => (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              sx={{ color: "#3f51b5" }}
              onClick={() => {
                onEyeClick(row);
              }}
            >
              <VisibilityIcon style={{ fontSize: 18 }} />
            </IconButton>
            {row.status !== "approved" && row.status !== "rejected" && (
              <IconButton
                sx={{ color: "#f50057" }}
                onClick={() => {
                  setOpenDelete(true);
                  setItemToDelete(row);
                }}
              >
                <DeleteIcon style={{ fontSize: 18 }} />
              </IconButton>
            )}
          </Box>
        </TableCell>
      ),
    },
  ];

  useQuery(GET_ITEMS_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      formDispatch({ type: FormActions?.SET_ORDER_ITEMS, payload: data.items });
    },
  });

  console.log("item to delete", itemToDelete);

  // Fetch Pending Finance Transactions..
  const { refetch } = useQuery(financeQuery[props.type], {
    variables: { cityId: cityId, first: 100 },
    fetchPolicy: "cache-and-network",

    onCompleted(data) {
      if (data) {
        let result;
        console.log("result", data);
        result =
          props?.type === "pending"
            ? data?.cityToBeApproved_Orders
            : data?.mySubmitedOrders;
        //Sales Transactions...
        changeToObjectFunction(result.salesTransactions);

        //Payment Transaction
        ObjectTransactionFunction(result.paymentTransactions);
        console.log("fetched pending Data", result.paymentTransactions);

        console.log("fetched pending Data Sales", result?.salesTransactions);
        setSalesLoader(false);
        setPaymentsLoader(false);
      }
    },
    onError(error) {
      if (props.type === "pending" && !cityId) {
        console.error("Error: City is not selected.", error);
        toast.error("City must be selected.");
        setSalesLoader(false);
        setPaymentsLoader(false);
      } else {
        toast.error(error.message);
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (cityId) {
      setSalesLoader(true);
      setPaymentsLoader(true);
      refetch().then(() => {
        setSalesLoader(false);
        setPaymentsLoader(false);
      });
    }
  }, [cityId]);

  //Delete Transactions Functions..
  const [deleteOrder] = useMutation(DELETE_SALES_ORDER_PAYMENT, {
    refetchQueries: [
      "GET_MY_SUBMITTED_ORDERS",
      {
        query: FETCH_PENDING_FINANCE_TRANSACTION,
        variables: { cityId: cityId },
      },
    ],
    onCompleted(data) {
      toast.success("Record deleted successfully");
      setOpenDelete(false);
      refetch();
      // refetch()
    },
    onError(e) {
      alert(e?.message || "Error");
    },
  });

  const [deleteSalesOrder] = useMutation(DELETE_SALES_ORDER, {
    refetchQueries: ["GET_MY_SUBMITTED_ORDERS"],
    onCompleted(data) {
      toast.success("Record deleted successfully");
      setOpenDelete(false);
      // refetch()
    },
    onError(e) {
      alert(e?.message || "Error");
    },
  });

  const changeToObjectFunction = (arr) => {
    console.log("called.. sales", arr);
    let newArray = [];
    arr?.map((me) => {
      newArray.push({
        type: "sales",
        id: me?.id,
        date: me?.date,
        customer: me?.customer.name,
        customerId: Number(me?.customer?.id),
        source: me?.source,
        salesUser: me?.salesUser?.username,
        currencyCode: me?.currency?.code,
        invoiceAmount: getInvoiceAmount(me?.saleorderdetail),
        status: me?.status,
        salesOrderDetails: me?.saleorderdetail,
        isFinalized: me.isFinalized,
        location: me.location,
        department: me.department,
        repId: me?.rep?.id,
        currencyId: me.currency.id,
        memo: me.memo,
      });
    });
    setFilterSale([...newArray]);
    setSalesTransaction(newArray);
    console.log("newArray", newArray);
  };

  const ObjectTransactionFunction = (arr) => {
    try {
      let newArray = [];
      arr?.map((me) => {
        newArray.push({
          type: "transaction",
          id: Number(me.id),
          customerId: Number(me?.customer?.id),
          currencyId: me.currency.id,
          cityId: Number(me.city.id),
          date: me.date,
          invoiceAmount: me?.amount,
          memo: me.memo,
          tix: me.tix,
          salesUser: me?.salesUser?.username,
          source: me?.source,
          customer: me.customer.name,
          status: me?.status,
          location: me?.location,
          department: me?.department,
          account: me?.bankAccount,
          isFinalized: me.isFinalized,
        });
      });
      setFilterPayments([...newArray]);
      setPaymentTransactions(newArray);
      console.log("newArray2", newArray);
    } catch (e) {
      console.error({ e });
    }
  };

  // get Amount Functions..

  const oSubmit = () => {
    if (itemToDelete.type === "transaction") {
      console.log("delete id", itemToDelete?.id);
      deleteOrder({
        variables: { id: Number(itemToDelete?.id) },
      });
    } else {
      deleteSalesOrder({
        variables: { id: Number(itemToDelete?.id) },
      });
    }
  };

  const onFilterDate = (dates) => {
    let startDate = new Date(dates.startDate);
    let endtDate = new Date(dates.endDate);

    let filterSalesRecords = filterSales.filter((me) => {
      let date = new Date(me.date);
      date.setHours(0, 0, 0);
      return date >= startDate && date <= endtDate;
    });

    let filterPaymentsRecords = filterPayments.filter((me) => {
      let date = new Date(me.date);
      date.setHours(0, 0, 0);
      return date >= startDate && date <= endtDate;
    });
    console.log({ filterPayments, filterPaymentsRecords });

    setPaymentTransactions([...filterPaymentsRecords]);
    setSalesTransaction([...filterSalesRecords]);
  };

  console.log("salesTransaction", salesTransaction);

  return (
    <Grid className="finance">
      <Grid className="finance__title">
        <Box className="pageTitle">
          <Typography variant="h3">
            {props?.module === "sales" ? "Sales" : "Finance"}
          </Typography>
          <ul className="breadcrumb">
            <li className="cursor" onClick={() => navigate("/crm")}>
              Dashboard
            </li>
            {props.type === "pending" ? (
              <li className="cursor" onClick={() => navigate("/finance")}>
                Finance
              </li>
            ) : (
              <>
                <li
                  className="cursor"
                  onClick={() =>
                    props?.module === "finance"
                      ? navigate("/finance")
                      : navigate("/sales")
                  }
                >
                  {props?.module === "finance" ? "Finance" : "Sales"}
                </li>
                <li className="cursor">History</li>
              </>
            )}
          </ul>
        </Box>
        <DateRangeBox
          title="Customer Date Range:"
          addClass={"searchBox"}
          type="finance"
          onChangeDate={onFilterDate}
        />

        <CitySearch getPendingTransaction={(id) => refetch()} />

        {props.type === "pending" ? (
          <ButtonBox
            type="contained"
            click={() => navigate("/finance/history")}
            btnClass="btn-secondary"
            label="History"
          />
        ) : (
          <ButtonBox
            type="contained"
            click={() =>
              props?.module === "finance"
                ? navigate("/finance")
                : navigate("/sales")
            }
            btnClass="btn-secondary"
            label={props?.module === "finance" ? "Finance" : "Customer"}
          />
        )}
      </Grid>
      <Grid className="finance__content">
        <TabBox
          items={[
            {
              element: salesLoader ? (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              ) : salesTransaction.length > 0 ? (
                <TableBox headers={headers} data={salesTransaction} />
              ) : (
                <NotFound text="No data found of sales" />
              ),
              label: "Sales",
            },
            {
              element: paymentsLoader ? (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              ) : paymentTransactions.length > 0 ? (
                <TableBox headers={headers} data={paymentTransactions} />
              ) : (
                <NotFound text="No data found of payments" />
              ),
              label: "Payments",
            },
          ]}
        />
        {/* {console.log('PT',paymentTransactions,paymentTransactions.map(me=>me.city))} */}
        {openSale && (
          <AddSaleDialog
            open={openSale}
            handleClose={() => setOpenSale(false)}
            title="Record a sales Transaction"
            customerData={customerData}
            rowData={currentRow}
            sales={props?.module === "sales" ? true : false}
            from={props?.module === "sales" ? "sales" : "finance"}
          />
        )}

        <DeleteDialogBox
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          title="Delete"
          handleDelete={oSubmit}
          text={`Are you sure you want to delete ${itemToDelete?.customer}?`}
        />
        {showPaymentModal && (
          <MakeSalesPayment
            handleClose={() => setPaymentModal(false)}
            open={showPaymentModal}
            data={currentRow}
            city={cityId}
            refetch={refetch}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default {
  History: () => (
    <Finance
      type="history"
      module={
        window.location.pathname === "/finance/history" ? "finance" : "sales"
      }
    />
  ),
  Pending: () => <Finance type="pending" />,
};
