import React, { useContext, useMemo, useState } from "react";
import { Box, Grid, IconButton, TableCell } from "@mui/material";
import NotFound from "components/common/NotFound";
import Popover from "components/common/Popover";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialogBox from "components/common/DeleteDialogBox";
import TableBox from "components/common/TableBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import personState from "store/atoms/person/personAtom";
import { useRecoilState } from "recoil";
import {
  INTERACTION_CREATE_MUTATION,
  INTERACTION_DELETE_MUTATION,
} from "graphql/mutation";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { FormContext } from "context/FormContext";
import { PERSON_SET_QUERY } from "graphql/person";
import SalesFormDialog from "components/sales/SalesFormDialog";
import { FETCH_SALES_CUSTOMER_INTERACTION } from "graphql/query/sales";

const Interaction = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openSalesForm, setSalesFormOpen] = useState(false);
  const [formData, setFormData] = useState<any>({
    formTitle: "Loyal Visit Form",
    formData: [],
    formType: null,
  });
  const [person, setPerson] = useRecoilState(personState);
  const { formState: FormState } = useContext(FormContext);
  const [formlist, setFormList] = useState(
    FormState.forms?.filter((item) => item.isNutrition === true)
  );
  const [update, setUpdate] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const popupList = useMemo(() => {
    return Object.keys(formlist).map((formId, index) => ({
      id: index + 1,
      title: formlist[formId].name, // Assuming 'name' is the property to be displayed
      onClick: () => {
        const selectedForm = formlist[formId];
        console.log(selectedForm);
        setSalesFormOpen(true);
        setUpdate(false);
        setFormData({
          formData: selectedForm,
          formTitle: selectedForm.name,
          formType: selectedForm.id,
        });
      },
    }));
  }, [formlist]);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  //Delete

  const [deleteInteraction, { data: interactionDelete, loading, error }] =
    useMutation(INTERACTION_DELETE_MUTATION, {
      refetchQueries: [
        {
          query: PERSON_SET_QUERY,
          variables: {
            id: parseInt(person?.id),
          },
        },
      ],
      onCompleted(data) {
        toast.success("Interaction is deleted successfully");
        setOpenDelete(false);
      },
      onError(e) {
        setOpenDelete(false);
        toast.error(e.message);
      },
    });

  const headers = [
    {
      key: "name",
      label: "Interaction Type",
    },
    {
      key: "id",
      label: "ID",
    },
    {
      key: "dateOfVisited",
      label: "Date of Visit",
    },
    {
      key: "visitedBy",
      label: "Visited by",
    },
    {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element: ({ row }) => (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton sx={{ color: "#3f51b5" }}>
              <VisibilityIcon
                style={{ fontSize: 18 }}
                onClick={() => handleViewInteraction(row)}
              />
            </IconButton>
            <IconButton
              sx={{ color: "#f50057" }}
              onClick={() => {
                setItemToDelete(row);
                setOpenDelete(true);
              }}
            >
              <DeleteIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        </TableCell>
      ),
    },
  ];

  const handleViewInteraction = (data) => {
    setUpdate(true);

    const formInfo = {
      index: data?.form?.id,
      name: data?.form?.name,
      type: data?.form?.Ftype,
    };

    if (formInfo) {
      const { index, name, type } = formInfo;
      setSalesFormOpen(true);
      const form = formlist?.filter((item) => item.id === formInfo.index);
      console.log(form, "asas");

      setFormData({
        formData: form[0],
        formTitle: name,
        formType: type,
        updateData: data,
      });
    }
  };

  const [InteractionCreateMutation] = useMutation(INTERACTION_CREATE_MUTATION, {
    refetchQueries: [
      {
        query: PERSON_SET_QUERY,
        variables: {
          id: parseInt(person?.id),
        },
      },
    ],
    onCompleted(data) {
      if (data.CreateInterAction) {
        setSalesFormOpen(false);
        setUpdate(false);
        setSubmitLoader(false);

        if (!update) {
          toast.success("Interaction created sucessfully");
        } else {
          toast.success("Interaction updated sucessfully");
        }
      }
    },
    onError(error) {
      toast.error(error.message);
      setSubmitLoader(false);
    },
  });

  const handleDelete = () => {
    deleteInteraction({ variables: { id: parseInt(itemToDelete?.id) } });
  };

  const onCreate = (data) => {
    setSubmitLoader(true);
    InteractionCreateMutation(data);
  };

  return (
    <React.Fragment>
      {person?.interaction?.length ? (
        <Grid className="person">
          <TableBox headers={headers} data={person?.interaction} />
        </Grid>
      ) : (
        <NotFound text="No data found of interaction" />
      )}

      <DeleteDialogBox
        open={openDelete}
        handleClose={handleDeleteClose}
        title="Delete"
        handleDelete={handleDelete}
        text={`Are you sure you want to delete ${itemToDelete?.id}?`}
      />

      {openSalesForm && (
        <SalesFormDialog
          title={formData.formTitle}
          open={openSalesForm}
          form={formData.formData}
          formType={formData.formType}
          updateData={update && formData.updateData}
          sales={false}
          handleClose={() => {
            setSalesFormOpen(false);
            setUpdate(false);
          }}
          createSaleFun={onCreate}
          submitLoader={submitLoader}
          edit={update}
        />
      )}

      <Popover items={popupList}>
        <IconButton aria-label="add" className="addPopup">
          <AddIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  );
};
export default Interaction;
