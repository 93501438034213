import React, { useContext, useState, useEffect, memo } from 'react'

import { Autocomplete, TextField } from '@mui/material'
import { AutoCompleteData } from 'modals/select';
import { AuthContext } from "context/AuthContext"
import { useLazyQuery } from '@apollo/client';
import { CITY_LIST_CUSTOMER, FETCH_FINANCE_ACCOUNTS } from 'graphql/query';
import { FormActions, FormContext, useFormReducer } from 'context/FormContext';
import { useRecoilState } from 'recoil';
import { KEYS, listProviderAtom } from 'store/atoms/city';
import { useLocation } from 'react-router-dom';



type CitySearchProps = {
    getPendingTransaction?: (id: number) => {}
};

export const CitySearch: React.FC<CitySearchProps> = ({ getPendingTransaction }) => {


    const location = useLocation()
    const { userDetails } = useContext(AuthContext);
    const { formState, formDispatch } = useFormReducer()
    const [allCity] = useRecoilState(listProviderAtom(KEYS.CITY));
    const [_, setPrefferedStore] = useRecoilState(listProviderAtom(KEYS.PREFERRED_STORE))
    const userCityList = userDetails?.availableCities || [];

    const { formState: FormState } = useContext(FormContext);

    const { currentCity } = FormState



    const cityList = userCityList.map((me: any) => {
        return {
            name: me.shortName,
            id: Number(me.id)
        }
    })

    const [getCities] = useLazyQuery(CITY_LIST_CUSTOMER, {
        fetchPolicy: 'cache-and-network',
        onCompleted(data) {
        const result = data.citystore
        setPrefferedStore(result)
        },
        onError(error) {
            console.error(error)
        },

    });  
    useEffect(()=>{ 
        const city = Object.keys(currentCity).length === 0 ? userCityList[0] : currentCity;
        console.log("calling sdsd",city);
        const cityId = Number(city.id);
        
        updateCityDepndancies(cityId);
    },[currentCity]) 

   
    const updateCityDepndancies= (cityId:number) => {
        getCities({ variables: { id: cityId } })
        getFinance({ variables: { id: cityId } })
        formDispatch({
            type: FormActions.SET_CURRENT_CITY_DROPDOWN_DATA,
            payload: allCity.filter((each) => each.id === cityId)
        });

        if (location.pathname === "/finance"){

            getPendingTransaction(cityId)
    }

    }


    const [getFinance] = useLazyQuery(FETCH_FINANCE_ACCOUNTS);

    const onCityChange = (e, value) => {

        if (value) {
            let city = userCityList.find(me => Number(me.id) === value.id);
            formDispatch({
                type: FormActions.SET_CURRENT_CITY,
                payload: city
            });
        }
    };


    return (
        <>
            <Autocomplete
                multiple={false}
                freeSolo
                className={'searchBoxCity'}
                size='small'
                value={Number(currentCity?.id) || ""}
                id="autoComplete"
                options={cityList}
                onChange={(e, value) => {

                    onCityChange(e, value)
                }}
                isOptionEqualToValue={(option, value) => {
                    return option?.id === value
                }}
                getOptionLabel={(option: any) => {
                    return option?.name || cityList.find((me: any) => me?.id === option)?.name || option || ""
                }}
                renderInput={(params) => (
                    <TextField  {...params} size="small" label={'Select City'} />

                )}

            />
        </>
    )
};

export default memo(CitySearch)
