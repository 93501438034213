import { useLazyQuery } from "@apollo/client";
import { ME_QUERY } from "graphql/query";
import * as React from "react";

let initialValues = {
  isLoggedIn: false,
  doLogin: () => {},
  setToken: (value: string) => {},
  mappUserDetails: (value: any) => {},
  setAllowedApps: (value: any) => {},
  token: null,
  userDetails: {},
  // setUserDetails: (value: any) => { }
  childDelete: false,
  availableCities: [],
  allowApps: [],
  loading: false,
};

export const AuthContext = React.createContext(initialValues);
export const AuthContextProvider = ({ children }) => {
  const [_isLoggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [_token, mapToken] = React.useState<string>(null);
  const [_userDetails, _mappUserDetails] = React.useState<string>(null);
  const [_childDelete, setChildDelete] = React.useState<boolean>(false);
  const [allowedApps, setAllowedApps] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [getUserDetails] = useLazyQuery(ME_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const me = data.me;
      allowedRoutes(me);
      let availableCities = {
        availableCities: me.additionalCities.concat(me.city),
      };
      let result = Object.assign({}, me, availableCities);
      _mappUserDetails(result);
      setLoading(false);
    },
    onError(error) {
      console.error(error);
    },
  });
  const _doLogin = () => {
    setLoggedIn(true);
  };

  const allowedRoutes = (data) => {
    if (!data || !data.apps || !Array.isArray(data.apps)) {
      console.error("Invalid data provided.");
      return;
    }

    const appNames = data.apps.map((app) => {
      if (app.name === "Nutrition") {
        return "crm";
      } else {
        return app.name;
      }
    });

    console.log("Extracted App Names:", appNames);

    // Assuming setAllowedApps is a state-setting function
    setAllowedApps(appNames);
  };

  const childDeleteFunction = () => {
    setChildDelete(true);
  };

  const _setToken = (value: string) => {
    localStorage.removeItem("t");
    localStorage.setItem("t", value);
    getUserDetails();

    mapToken(value);
  };

  const resetAuthContext = () => {
    initialValues = {};
  };

  const value = {
    isLoggedIn: _isLoggedIn,
    doLogin: _doLogin,
    setToken: _setToken,
    token: _token,
    userDetails: _userDetails,
    _childDelete: childDeleteFunction,
    mappUserDetails: _mappUserDetails,
    allowApps: allowedApps,
    setAllowedApps: setAllowedApps,
    loading: loading,
    //  availableCities
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
