import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AutocompleteBox from "components/common/AutocompleteBox";
import TabBox from "components/common/TabBox";
import CustomerInfo from "./customerInfo";
import ButtonBox from "components/common/ButtonBox";
import { useNavigate } from "react-router-dom";
import Transactions from "./transactions";
import Interaction from "./interaction";
import "./sales.scss";
import SearchInput from "components/common/extra/SearchInput";
import salesIdAtom from "store/atoms/sales/salesIdAtom";
import { useRecoilState } from "recoil";
import {
  STORE_CITY_SEARCH,
  FETCH_SALES_CUSTOMER_INTERACTION,
  GET_SUBMITTED_ORDER_TRANSACTION,
  TRANSACTIONS_BY_CUSTOMER,
} from "graphql/query/sales";
import { useLazyQuery, useQuery } from "@apollo/client";
import salesState from "store/atoms/sales/salesState";
import FormContextProvider, {
  FormContext,
  FormActions,
  useFormReducer,
} from "../../context/FormContext";
import customerDataState from "./saleAtom";
import CitySearch from "components/common/CitySearch";
import { getInvoiceAmount } from "utils/Constant";
import { GET_ITEMS_QUERY } from "graphql/query";

const Sales = () => {
  const navigate = useNavigate();
  const [customerLoader, setCustomerLoader] = useState(false);
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [interactionLoader, setInteractionLoader] = useState(false);

  const [searhValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [localpersonId, setLocalPersonId] = useState();
  const [sales, setSales] = useRecoilState(salesState);
  const [salesID, setSalesId] = useRecoilState(salesIdAtom);
  const [storeList, setStoreList] = useState([]);
  const [customerData, setCustomerData] = useRecoilState(customerDataState);
  const [customerTransactions, setCustomerTransactions] = useState([]);
  const [saleCustomerInteraction, setSaleCustomerInteraction] = useState([]);
  const [salesTransaction, setSalesTransaction] = useState([]);
  const { formState, formDispatch } = useFormReducer();
  const { formState: FormState } = useContext(FormContext);

  let env = process.env.NODE_ENV;
  const { currentCity } = FormState;

  const cityId = Number(currentCity.id);

  //  Handle OnChange Function ..
  const onInputChange = (e, v) => {
    let value = e?.target?.value;
    setSearchValue(v);

    if (e !== null && value !== 0) {
      setLoader(true);

      setTimeout(() => {
        getStores({
          variables: { id: Number(cityId), search: e.target.value },
        });
      }, 2000);
    } else if (v === "" || e === null) {
      setSearchValue("");
    }
  };

  useEffect(() => {
    getSalesCustomerInteraction({ variables: { customerId: Number(salesID) } });
    getSubmittedOrderTransaction({
      variables: { customerId: Number(salesID), skip: 0, first: 0 },
    });
  }, [salesID]);

  //Handle Select Function...
  const handleSelect = (e, value) => {
    let param = value.id;
    setLocalPersonId(param);
    setCustomerData(value.data);
    setSalesId(value.id);
    if (param) {
      setCustomerLoader(true);
      setTransactionLoader(true);
      setInteractionLoader(true);
      getSubmittedOrderTransaction({
        variables: { customerId: Number(param), skip: 0, first: 0 },
      });
      getSalesCustomerInteraction({ variables: { customerId: Number(param) } });
    }
  };

  useQuery(GET_ITEMS_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      formDispatch({ type: FormActions?.SET_ORDER_ITEMS, payload: data.items });
    },
  });

  const [getStores, { loading, error, data }] = useLazyQuery(
    STORE_CITY_SEARCH,
    {
      onCompleted(data) {
        if (data && data.stores) {
          let result = data?.stores?.map((store) => ({
            value: `${store?.entityId} | ${store?.name} | ${store?.rep?.shortName}`,
            label: `${store?.entityId} | ${store?.name} | ${store?.rep?.shortName}`,
            id: store?.id,
            data: store,
          }));
          if (result?.length === 0) {
            setOpen(false);
          }
          setStoreList([...result]);
          setLoader(false);
        }
      },
      onError(error) {
        setLoader(false);
      },
    }
  );

  //getSalesCustomerInteraction Function ...
  const [getSalesCustomerInteraction, {}] = useLazyQuery(
    FETCH_SALES_CUSTOMER_INTERACTION,
    {
      onCompleted(data) {
        setInteractionLoader(false);

        if (data && data.salesCustomerInterActions) {
          setSaleCustomerInteraction(data?.salesCustomerInterActions);
          formDispatch({
            type: FormActions.SET_SALES_INTERACTION,
            payload: data?.salesCustomerInterActions,
          });
        }
      },
      onError(error) {
        setInteractionLoader(false);
      },
    }
  );

  //Get customer transaction details..
  useQuery(TRANSACTIONS_BY_CUSTOMER, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: env === "development" ? 25016 : Number(salesID),
    },

    onCompleted(data) {
      setTransactionLoader(false);

      if (data?.transactions !== null) {
        // setCustomerTransactions([...data?.transactions])
        let transactions = data?.transactions;
        formDispatch({
          type: FormActions.SET_SALES_TRANSACTION,
          payload:
            transactions !== null && transactions?.length > 0
              ? data?.transactions
              : [],
        });
      }
    },
    onError(error) {
      formDispatch({ type: FormActions.SET_SALES_TRANSACTION, payload: [] });
      console.error("query error", error);
      setCustomerLoader(false);
      setTransactionLoader(false);
    },
  });

  //customerSubmitedOrders Function
  const [getSubmittedOrderTransaction, {}] = useLazyQuery(
    GET_SUBMITTED_ORDER_TRANSACTION,
    {
      onCompleted(data) {
        if (data && data.customerSubmitedOrders) {
          // setCustomerLoader(true)
          let result = data?.customerSubmitedOrders?.salesTransactions;
          let filtertedData = result?.filter((me) => !me.isFinalized);
          if (filtertedData?.length > 0) changeToObjectFunction(filtertedData);
          else {
            setCustomerLoader(false);
            setSalesTransaction([...filtertedData]);
          }
        }
      },
      onError(error) {
        setCustomerLoader(false);

        // setTransactionLoader(false)
      },
    }
  );

  const changeToObjectFunction = (arr) => {
    let newArray = [];
    arr?.map((me) => {
      newArray.push({
        type: "sales",
        id: me?.id,
        date: me?.date,
        customer: me?.customer,
        salesUser: me?.salesUser?.username,
        currencyCode: me?.currency?.code,
        invoiceAmount: getInvoiceAmount(me?.saleorderdetail),
        status: me?.status,
        salesOrderDetails: me?.saleorderdetail,
        memo: me.memo,
      });
    });
    setSalesTransaction([...newArray]);
    setCustomerLoader(false);
  };

  return (
    <Grid className="salesPage">
      <Grid className="salesPage__title">
        <Box className="pageTitle">
          <Typography variant="h3">Sales List</Typography>
          <ul className="breadcrumb">
            <li>Dashboard</li>
            <li>Sales</li>
          </ul>
        </Box>
        <SearchInput
          title={"Search Customer"}
          addClass={"searchBox"}
          onInputChange={onInputChange}
          handleSelect={handleSelect}
          data={storeList}
          value={searhValue}
          loading={loader}
          open={open}
        />
        <CitySearch />

        <ButtonBox
          type="contained"
          click={() => navigate("/sales/history")}
          btnClass="btn-secondary"
          label="History"
        />
      </Grid>
      <Grid className="salesPage__content">
        <TabBox
          items={[
            {
              element: (
                <CustomerInfo
                  custdata={customerData}
                  salesTransactiondata={salesTransaction}
                  loader={customerLoader}
                />
              ),
              label: "Customerinfo",
            },
            {
              element: (
                <Transactions
                  Transactiondata={customerTransactions}
                  custdata={customerData}
                  loader={transactionLoader}
                />
              ),
              label: "Transactions",
            },
            {
              element: (
                <Interaction
                  salesInteractionData={saleCustomerInteraction}
                  custdata={customerData}
                  loader={interactionLoader}
                />
              ),
              label: "Interaction",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default Sales;
