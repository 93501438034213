import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation ($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation (
    $phoneNumber: String!
    $username: String!
    $password: String!
    $city: Int!
    $name: String!
    $area: Int!
    $nhood: Int!
    $gender: String!
  ) {
    CreateUser(
      input: {
        username: $username
        phoneNumber: $phoneNumber
        password: $password
        city: $city
        name: $name
        area: $area
        nhood: $nhood
        gender: $gender
      }
    ) {
      username
      password
    }
  }
`;
export const DELETE_SALES_ORDER = gql`
  mutation ($id: Int!) {
    SalesDeleteSalesOrder(id: $id)
  }
`;

export const DELETE_SALES_ORDER_PAYMENT = gql`
  mutation ($id: Int!) {
    SalesDeletePaymentsPreAuthoriz(id: $id)
  }
`;

export const PERSON_BASE_CREATE_MUTATION = gql`
  mutation (
    $id: Int
    $phone: String!
    $name: String!
    $city: Int!
    $area: Int!
    $nhood: Int!
    $gender: String!
  ) {
    CreatePerson(
      createPerson: {
        id: $id
        phone: $phone
        name: $name
        city: $city
        area: $area
        nhood: $nhood
        gender: $gender
      }
    ) {
      id
      name
      gender
      phoneNumber
      city {
        id
        name
      }
      area {
        id
        name
      }
      nhood {
        id
        name
      }
      createdDate
      modifiedDate
      interaction {
        id
        comment
        isPositive
        dateOfVisited
        interactionBy {
          id
          shortName
        }
        form {
          id
          name
          Ftype
        }
        answer {
          question {
            id
            name
            displayTitle
          }
          answer
        }
      }
    }
  }
`;
export const PERSON_MOTHTER_CREATE_MUTATION = gql`
  mutation (
    $id: Int
    $person: Int!
    $employment: String!
    $source: String!
    $ageGroup: String
    $education: String!
    $facebook: Boolean
    $nochildren: Int!
    $whatsapp: Boolean
    $smartphone: Boolean
    $doYouHaveLicense: Boolean
    $ownhouse: Boolean
    $socialMediaPlatform: String!
    $preferredStore: Int!
    $contactPreference: String!
  ) {
    CreateMother(
      createMother: {
        id: $id
        person: $person
        employment: $employment
        source: $source
        ageGroup: $ageGroup
        education: $education
        facebook: $facebook
        nochildren: $nochildren
        whatsapp: $whatsapp
        smartphone: $smartphone
        doYouHaveLicense: $doYouHaveLicense
        ownhouse: $ownhouse
        preferredStore: $preferredStore
        socialMediaPlatform: $socialMediaPlatform
        contactPreference: $contactPreference
      }
    ) {
      id
      person {
        id
        name
      }
      employment
      source
      ageGroup
      education
      hasFacebook
      nochildren
      hasWhatsapp
      smartphone
      doYouHaveLicense
      ownsHouse
      preferredStore {
        id
        entityId
        name
      }
      contactPreference
      advice
      healthIssue
      vitamens
      householdLevel
      child {
        id
        name
        vaccine
        gender
        hospital {
          id
          name
        }
        DOB
        visits
      }
    }
  }
`;

export const PERSON_CHILD_CREATE_MUTATION = gql`
  mutation (
    $id: Int
    $mother: Int!
    $name: String!
    $hospital: Int!
    $DOB: String!
    $vaccine: String!
    $gender: String!
  ) {
    CreateChild(
      createChild: {
        id: $id
        mother: $mother
        name: $name
        vaccine: $vaccine
        hospital: $hospital
        DOB: $DOB
        gender: $gender
      }
    ) {
      id
      name
      vaccine
      gender
      hospital {
        id
        name
      }
      DOB
      visits
    }
  }
`;

export const BASEPERSON_DELETE_MUTATION = gql`
  mutation ($id: Int!) {
    deletePerson(id: $id)
  }
`;

export const MOTHER_DELETE_MUTATION = gql`
  mutation ($id: Int!) {
    deleteMother(id: $id)
  }
`;

export const CHILD_DELETE_MUTATION = gql`
  mutation ($id: Int!) {
    DeleteChild(id: $id)
  }
`;

export const INTERACTION_CREATE_MUTATION = gql`
  mutation (
    $id: Int
    $isPositive: Int!
    $comment: String!
    $form: Int!
    $city: Int!
    $area: Int!
    $nhood: Int!
    $person: Int!
    $interactionBy: Int!
    $dateOfInteraction: String!
    $questionAnswer: [QuestionAnswerTypes!]!
  ) {
    CreateInterAction(
      Inter: {
        id: $id
        isPositive: $isPositive
        comment: $comment
        form: $form
        city: $city
        area: $area
        nhood: $nhood
        person: $person
        interactionBy: $interactionBy
        dateOfInteraction: $dateOfInteraction
        questionAnswer: $questionAnswer
      }
    ) {
      id
      comment
      isPositive
      dateOfVisited
      area {
        id
        name
      }
      nhood {
        id
        name
      }
      interactionBy {
        id
        shortName
        person {
          name
          id
        }
      }
      form {
        id
        name
        Ftype
      }
      answer {
        question {
          id
          name
          displayTitle
        }
        answer
      }
    }
  }
`;

export const CREATE_SALES_INTERACTION = gql`
  mutation (
    $id: Int
    $isPositive: Int!
    $comment: String!
    $form: Int!
    $customer: Int!
    $city: Int!
    $dateOfInteraction: String!
    $questionAnswer: [QuestionAnswerTypes!]!
  ) {
    CreateSalesInterAction(
      SalesInter: {
        id: $id
        comment: $comment
        form: $form
        city: $city
        isPositive: $isPositive
        customer: $customer
        dateOfInteraction: $dateOfInteraction
        questionAnswer: $questionAnswer
      }
    ) {
      id
      comment
      isPositive
      dateOfVisited

      interactionBy {
        id
      }
      form {
        id
        name
        Ftype
      }
      salesanswer {
        question {
          id
          name
          displayTitle
        }
        salesanswer
      }
    }
  }
`;

export const INTERACTION_DELETE_MUTATION = gql`
  mutation ($id: Int!) {
    DeleteInterAction(id: $id) {
      id
      comment
      isPositive
      dateOfVisited
      interactionBy {
        id
        shortName
      }
      form {
        id
        name
        Ftype
      }
      answer {
        question {
          id
          name
          displayTitle
        }
        answer
      }
    }
  }
`;

export const PAYMENT_PRE_AUTHORIZE_PAYMENT = gql`
  mutation ($input: PaymentPreAuthorizeInput!) {
    CreatePaymentsPreAuthorize(input: $input) {
      id
      customer {
        id
        name
      }
      memo
      customer {
        id
        name
      }
      currency {
        id
        code
      }
    }
  }
`;

export const CREATE_SALES_ORDER_MUTAITON2 = gql`
  mutation ($input: SalesOrderInput!, $source: String) {
    CreateSalesOrder(input: $input, source: $source) {
      id
      customer {
        id
        name
      }
      currency {
        id
        code
      }
      saleorderdetail {
        item {
          id
        }
        price
        id
        qty
      }
    }
  }
`;

export const CHECK_GROSS_MARGIN = gql`
  query ($input: SalesOrderInput!) {
    checkGrossMargin(input: $input) {
      grossMargin
    }
  }
`;

export const DELETE_SALES_INTERACTION = gql`
  mutation ($id: Int!) {
    DeleteSalesInterAction(id: $id) {
      id
      comment
      isPositive
      form {
        id
        name
        Ftype
      }
      interactionBy {
        id
        username
      }
      salesanswer {
        question {
          id
          name
          displayTitle
        }
        salesanswer
      }
    }
  }
`;

export const APPROVE_PENDING_FINANCE_SALES_TRANSACRION = gql`
  mutation ($id: Int!, $isApproved: Boolean!, $source: String!) {
    approveRejectSalesOrder(id: $id, isApproved: $isApproved, source: $source) {
      message
      salesOrder {
        id
        status
        __typename
      }
      __typename
    }
  }
`;
export const SALES_APPROVAL_STORE_ORDER = gql`
  mutation ($id: Int!, $isApproved: Boolean!, $source: String!) {
    salesApprovalStoreOrder(id: $id, isApproved: $isApproved, source: $source) {
      salesOrder {
        id
        status
      }
      message
    }
  }
`;

export const APPROVE_PENDING_FINANCE_PAYMENT_TRANSACRION = gql`
  mutation ($id: Int!, $isApproved: Boolean!) {
    approveRejectPaymentPreauthrize(id: $id, isApproved: $isApproved) {
      status
    }
  }
`;

export const Finance_Payment_Pre_Authorize = gql`
  mutation ($input: FinancePaymentPreAuthorizeInput!) {
    FinancePaymentsPreAuthorize(input: $input) {
      id
    }
  }
`;

export const GET_MY_SUBMITTED_ORDERS = gql`
  query GET_MY_SUBMITTED_ORDERS($first: Int, $skip: Int) {
    mySubmitedOrders(first: $first, skip: $skip) {
      salesTransactions {
        id
        date
        status
        memo
        source
        rep {
          id
          name
        }
        isFinalized
        customer {
          id
          name
        }
        currency {
          id
          code
        }
        salesUser {
          id
          username
          firstName
        }
        financeUser {
          id
          username
          firstName
        }
        department {
          id
          name
        }
        location {
          id
          name
        }
        city {
          id
          name
        }
        saleorderdetail {
          id
          item {
            id
            name
          }
          price
          qty
          type
        }
      }

      paymentTransactions {
        id
        date
        source
        department {
          id
          name
        }
        location {
          id
          name
        }
        currency {
          id
          code
        }
        memo
        tix
        bankAccount {
          id
          name
        }
        salesUser {
          id
          username
          firstName
        }
        financeUser {
          id
          username
          firstName
        }
        customer {
          id
          entityId
          name
        }
        city {
          id
          name
        }

        amount
        status
        isFinalized
      }
    }
  }
`;

export const UPDATE_FINANCE_SALES_ORDER = gql`
  mutation ($input: SalesOrderFinanceInput!, $source: String!) {
    FinanceUpdateSalesOrder(input: $input, source: $source) {
      id
      __typename
    }
  }
`;
